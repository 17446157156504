<template>
  <div class="container-fluid no-scroll px-0">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center fw-bold fs-4 mb-3 mt-5">
          Messages
        </div>
        <div v-if="messages.length === 0" class="col-12 bg-white my-2 py-2 text-center">
          <img src="../../assets/svgs/com_ic_empty.svg" style="height: 5rem;">
          <div class="text-secondary">
            <span>No messages</span>
          </div>
        </div>
        <div v-else class="col-12 bg-white small my-1 py-1" v-for="message in messages" v-bind:key="message.id">
          <div class="message">
            <span class="title" :class="{'active': !message.read}"><fa icon="bell" type="far" class="fas"/> {{ message.title }}</span>
            <span class="time">{{ fromNow(message.create_at) }}</span>
          </div>
          <div class="content" :class="{'text-secondary': message.read }">
            <div>{{ message.message }}</div>
            <div><button v-if="!message.read" class="btn btn-sm read" @click="read(message.id, true)">Set as read</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

import { getUserMessages, updateUserMessage } from '../../api/user'

import { fromNow } from '../../utils/util'

export default {
  name: 'MessageList',

  setup () {
    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)
    const messageCount = computed(() => store.state.message.messageCount)
    const updateMessageCount = (count) => store.dispatch('message/updateMessageCount', count)
    return {
      userId,
      messageCount,
      updateMessageCount
    }
  },

  created () {
    this.loadMessages()
  },

  data () {
    return {
      messages: []
      // pager: {
      //   current: 1,
      //   pageSize: 20,
      //   total: 0
      // }
    }
  },

  methods: {
    loadMessages () {
      this.$loading.show('Loading messages...')
      const params = {
        id: this.userId
        // ,
        // pager: {
        //   ...this.pager,
        //   offset: (this.pager.current - 1) * this.pager.pageSize,
        //   limit: this.pager.pageSize
        // }
      }
      getUserMessages(params).then(res => {
        if (res.code === 0) {
          this.messages = res.data.messages
          // this.pager.total = res.data.total
        } else {
          this.$toast.error('Failed to load user messages, please try again later')
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    go (id, isRead, link) {
      if (!isRead) {
        updateUserMessage({
          id: id,
          read: true
        }).then(res => {
          if (res.code === 0) {
            if (!isRead) {
              this.messages.filter(item => item.id === id)[0].read = true
              this.updateMessageCount(this.messageCount - 1)
            }
          }
        })
      }
      this.$router.push({
        path: link
      })
    },

    read (id, isRead) {
      const params = {
        id: id,
        read: isRead
      }

      updateUserMessage(params).then(res => {
        if (res.code === 0) {
          this.messages.filter(item => item.id === id)[0].read = isRead

          if (isRead) {
            this.updateMessageCount(this.messageCount - 1)
          } else {
            this.updateMessageCount(this.messageCount + 1)
          }
        }
      })
    },

    fromNow
  }
}
</script>

<style lang="scss" scoped>

.message{
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  .title{
    font-weight: bold;
    &.active {
      color: #de350b
    }
  }
  .time{
    font-size: 0.8rem;
  }
}

.content {
  border-top: 1px solid #f1f1f1;
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .read {
    background: #ccc;
    color: black;
  }
}

.fas {
  height: 1rem;
}

</style>
