<template>
  <div class="container-fluid no-scroll bg-white px-0">
    <div class="container">
      <div class="position-relative px-0 px-lg-4">
          <div class="row d-none d-lg-block">
            <div class="col-12 d-flex px-0 justify-content-between align-items-center">
              <div class="col-8">
                <img src="../assets/logo.svg" style="height: 1.5rem; margin-right: 3rem;">
                <router-link v-for="menu in menus" class="header-link-h mx-4"
                             :class="{ active: path === menu.to }" v-bind:key="menu.name" :to="menu.to">{{ menu.name }}
                </router-link>
              </div>
              <div v-if="isLoggedIn" class="col-4 text-end" style="height: 4rem;">
                <router-link to="/message/list" class="header-link-fa" :class="{'active': path.includes('/message')}">
                  <span style="position: relative; margin-right: 1.4rem;">
                    <fa icon="bell" type="far" class="header-fa"/>
                    <span v-if="messageCount > 0" class="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill">{{ messageCount }}</span>
                  </span>
                </router-link>
                <router-link to="/wishlist/list" class="header-link-fa" :class="{'active': path.includes('/wishlist')}">
                  <fa v-if="path.includes('/wishlist')" icon="heart" type="fas" class="header-fa"></fa>
                  <fa v-else icon="heart" type="far" class="header-fa"></fa>
                </router-link>
                <router-link to="/cart/list">
                  <span style="position: relative;">
                      <img v-if="path.includes('cart')" src="../assets/svgs/com_nav_ic_shopping_sel.svg"
                           class="header-icon">
                      <img v-else src="../assets/svgs/com_nav_ic_shopping_nor.svg"
                           class="header-icon">
                      <span v-if="cartCount > 0" class="position-absolute top-0 translate-middle badge bg-danger rounded-pill" style="right: -0.4rem;">{{ cartCount }}</span>
                  </span>
                </router-link>
                <router-link to="/user/center">
                  <img v-if="path.includes('/user')" src="../assets/svgs/com_nav_ic_my_sel.svg" class="header-icon">
                  <img v-else src="../assets/svgs/com_nav_ic_my_nor.svg"
                       class="header-icon">
                </router-link>
              </div>
              <div v-else style="height: 4rem;" class="d-flex align-items-center">
                <router-link class="me-3 fw-bold" style="color: #F1651A" to="/user/login">Sign In</router-link>
              </div>
            </div>
          </div>
          <div class="row d-block d-lg-none">
            <div class="col-12 d-flex px-0 justify-content-between align-items-center">
              <img src="../assets/icons/com_nav_ic_menu_nor@3x.png" class="header-icon" @click="toggleMenu">
              <div v-if="isLoggedIn">
                <router-link to="/message/list" class="header-link-fa" :class="{'active': path.includes('/message')}">
                  <span style="position: relative; margin-right: 1.4rem;">
                    <fa icon="bell" type="far" class="header-fa"/>
                    <span v-if="messageCount > 0" class="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill">{{ messageCount }}</span>
                  </span>
                </router-link>
                <router-link to="/wishlist/list" class="header-link-fa" :class="{'active': path.includes('/wishlist')}">
                  <fa v-if="path.includes('/wishlist')" icon="heart" type="fas" class="header-fa"></fa>
                  <fa v-else icon="heart" type="far" class="header-fa"></fa>
                </router-link>
                <router-link to="/cart/list">
                  <span style="position: relative;">
                      <img v-if="path.includes('cart')" src="../assets/svgs/com_nav_ic_shopping_sel.svg"
                           class="header-icon">
                      <img v-else src="../assets/svgs/com_nav_ic_shopping_nor.svg"
                           class="header-icon">
                      <span v-if="cartCount > 0" class="position-absolute top-0 translate-middle badge bg-danger rounded-pill" style="right: -0.4rem;">{{ cartCount }}</span>
                  </span>
                </router-link>
                <router-link to="/user/center">
                  <img v-if="path.includes('/user')" src="../assets/svgs/com_nav_ic_my_sel.svg" class="header-icon">
                  <img v-else src="../assets/svgs/com_nav_ic_my_nor.svg"
                       class="header-icon">
                </router-link>
              </div>
              <router-link v-else class="me-3 fw-bold" style="color: #F1651A" to="/user/login">Sign In</router-link>
            </div>
          </div>
          <!--    </div>-->
        </div>
      <div class="menu-panel" :class="{ show: showMenu }" @touchmove.prevent @click="toggleMenu">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../assets/icons/com_nav_ic_menu_nor@3x.png" class="header-icon">
            <img src="../assets/icons/com_nav_ic_menu_close@3x.png" style="height: 1.2rem; padding-right: 1rem;"
                 @click.stop="toggleMenu">
          </div>

          <router-link v-for="menu in menus" class="header-link-v"
                       :class="{ active: path === menu.to }" v-bind:key="menu.name" :to="menu.to">{{ menu.name }}
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { getUserCartAndMessageCount } from '../api/user'

export default {
  name: 'HeaderV2',

  setup () {
    const route = useRoute()
    const path = computed(() => route.path)
    const store = useStore()
    const isLoggedIn = computed(() => store.state.auth.loggedIn)
    const userId = computed(() => store.state.auth.user.id)

    const messageCount = computed(() => store.state.message.messageCount)
    const cartCount = computed(() => store.state.cart.cartCount)

    const updateMessageCount = (count) => store.dispatch('message/updateMessageCount', count)
    const updateCartCount = (count) => store.dispatch('cart/updateCartCount', count)

    return {
      isLoggedIn,
      path,
      userId,
      cartCount,
      messageCount,
      updateMessageCount,
      updateCartCount
    }
  },

  created () {
    this.loadUserCartAndMessageCount()
  },

  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    },

    loadUserCartAndMessageCount () {
      if (this.isLoggedIn) {
        getUserCartAndMessageCount({
          id: this.userId
        }).then(res => {
          if (res.code === 0) {
            this.updateMessageCount(res.data.messageCount)
            this.updateCartCount(res.data.cartCount)
          }
        })
      }
    }
  },

  data () {
    return {
      menus: [
        {
          to: '/',
          name: 'Products'
        },
        {
          to: '/info/faq',
          name: 'FAQ'
        },
        {
          to: '/info/about',
          name: 'About'
        }
      ],
      showMenu: false
    }
  }
}
</script>

<style scoped lang="scss">

.active {
  font-weight: bold;
  color: #de350b;
}

.header-link-h {
  color: #333;
  font-size: 1.3rem;

  &.active {
    @extend .active;
  }
}

.header-link-v {
  display: block;
  padding: 1rem;
  width: 100%;
  color: #333;

  &.active {
    @extend .active;
    background: #eee;
  }
}

.header-icon {
  height: 3rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.header-link-fa {
  color: #555;
  &.active {
    color: #de350b;
    opacity: 0.8;
  }
  .header-fa {
    height: 1.8rem;
    position: relative;
  }
}

.menu-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: white;
  z-index: 9999;
  overflow: hidden;
  transition: 0.3s;

  &.show {
    width: 100%;
    overflow: visible;
  }
}

</style>
