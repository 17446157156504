<template>
  <div class="container-fluid no-scroll bg-white" style="margin-top: 1px;">
      <div class="row text-center py-5 small">
        <div class="col-12 font-weight-bold px-0">
          <span>Shanghai</span><span class="mx-2">·</span>
          <span>HongKong</span><span class="mx-2">·</span>
          <span>Dubai</span><span class="mx-2">·</span>
          <span>Jakarta</span><span class="mx-2">·</span>
          <span>Nairobi</span>
        </div>
        <div class="col-12 mt-4" style="line-height: 2rem;">
          &copy; 2019-2021 All rights reserved. <br>REER Electronics UAE L.L.C.<br>A subsidiary of <a href="http://reerglobal.com" target="_blank">ReerGlobal Group</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
