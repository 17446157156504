<template>
  <div class="container-fluid no-scroll bg-white">
    <div class="row px-sm-0 px-md-5">
      <div class="col-sm-6 col-12 pt-4">
        <p class="fw-bold fs-5">Business Contact</p>
        <ul class="footer-list">
          <li class="list-inline-item">
            <a href="https://wa.me/+9710564153112" target="_blank"><fa class="foot-icon" icon="whatsapp" type="fab"></fa></a>
            <div style="margin-left: 1rem;">
              <div class="fw-bold">Sales</div>
              <div class="mt-1" style="color: #777;">James +971 0564153112</div>
            </div>
          </li>
          <li class="list-inline-item">
            <a href="https://wa.me/+9710525438790" target="_blank"><fa class="foot-icon" icon="whatsapp" type="fab"></fa></a>
            <div style="margin-left: 1rem;">
              <div class="fw-bold">Jobs / Cooperation</div>
              <div class="mt-1" style="color: #777;">Joy +971 0525438790</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-6 col-12 pt-4">
        <p class="fw-bold fs-5">
          Follow Us On
        </p>
        <!-- Social Icons -->
        <ul class="footer-list list-inline d-flex justify-content-around">
          <li class="list-inline-item">
            <a href="https://facebook.com/reergood" target="_blank">
              <img src="../assets/svgs/com_bot_ic_facebook_nor.svg">
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://linkedin.com/company/reerglobal" target="_blank">
              <img src="../assets/svgs/com_bot_ic_in_nor.svg">
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <img src="../assets/svgs/com_bot_ic_twitter_nor.svg">
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <img src="../assets/svgs/com_bot_ic_instagram_nor.svg">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterInfo'
}
</script>

<style lang="scss" scoped>

.footer-list li {
  padding-top: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: inline-flex;
  align-items: center;

  a {
    img{
      width: 2.5rem;
      height: 2.5rem;
    }
    :hover {
      opacity: .6;
    }
  }

  .foot-icon {
    width: 2rem;
    height: 2rem;
    color: #de350b;

    :hover {
      opacity: .6;
    }
  }

  .flag {
    width: 2rem;
    height: 2rem;
  }
}

</style>
