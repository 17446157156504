<template>
  <HeaderV2></HeaderV2>
  <router-view/>
  <FooterInfo></FooterInfo>
  <Footer></Footer>
</template>

<script>

import FooterInfo from '../FooterInfo'
import Footer from '../Footer'
import HeaderV2 from '../HeaderV2'

export default {
  name: 'InfoLayout',
  components: { HeaderV2, FooterInfo, Footer }
}
</script>

<style scoped>

</style>
